import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = ['bunshi', 'izukunariya', 'tanikawa', 'jitensya', 'yama', 'meiji_vr', 'agc_kitte'];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        大学卒業後、映画製作会社の幻燈社に入社し「四季・奈津子」「マノン」「湾岸道路」などの東陽一監督の8作品に制作進行や製作担当などで参加。<br />
        テレビ番組のディレクターなどを経て、1987年「横浜博覧会、横浜市パビリオン」テーマ映像で初プロデュースする。その後、博覧会、博物館、企業展示館などの大型映像のプロデュースを担当する。<br />
        1994年株式会社モンタージュの設立に参画。<br />
        企業展示館、史料館、教育映像、科学映像など分野を問わず多くの作品を手掛ける。<br />
        また、ライフワークとして「人」をテーマにしたドキュメンタリーを製作し、全国のインデペンデント系映画館での上映を続けている。<br />
        2021年9月現職に就任
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return (
    <Container fluid className="memberAwards">
      <Row>
        <Col>
          <h3 className="titleTopBorder">Awards</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="awardsTitles">
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Works</Col>
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Award title</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">「結晶ができる瞬間をカメラで捉えた！」</Col>
            <Col xs={6} lg={6} className="col">第63回科学技術映像祭 部門優秀賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">理化学研究所　科学のフロンティア19<br />「RNAから読み解く生命の不思議」</Col>
            <Col xs={6} lg={6} className="col">第58回科学技術映像祭 部門優秀賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">「技術を継ぐ ～東京ゲートブリッジ橋梁上部築造工事記録～」</Col>
            <Col xs={6} lg={6} className="col">第53回科学技術映像祭 部門優秀賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">東京大学 理学部「東京大学理学部化学教室 150周年<br />ー化学を継ぐ君たちへー」</Col>
            <Col xs={6} lg={6} className="col">平成24年度優秀映像教材選奨 優秀作品賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">紀伊國屋書店「詩人・谷川俊太郎」</Col>
            <Col xs={6} lg={6} className="col">平成24年度優秀映像教材選奨 文部科学大臣賞</Col>
          </Row>

          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">サイエンスフロンティア21 ERATO研究成果映像記録<br />中村活性炭素クラスタープロジェクト 「炭素クラスターのためのソナタ」</Col>
            <Col xs={6} lg={6} className="col">第51回科学技術映像祭 部門優秀賞<br />映文連アワード2010 部門優秀賞 ソーシャル・コミュニケーション部門</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">JSTサイエンスチャンネル「ユータと不思議な宇宙の書 アストロペディア」</Col>
            <Col xs={6} lg={6} className="col">映文連アワード2009 部門優秀賞 ソーシャル・コミュニケーション部門</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">「水の都市　東京」</Col>
            <Col xs={6} lg={6} className="col">優秀映像教材選奨 優秀作品賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">「利根川」</Col>
            <Col xs={6} lg={6} className="col">映文連アワード2007 部門優秀賞 コーポレート・コミュニケーション部門<br />US International Film &amp; Video Festival シルバー・スクリーン賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">「アレクセイと泉」（ポレポレタイムス社）</Col>
            <Col xs={6} lg={6} className="col">第52回ベルリン国際映画祭 ベルリナー新聞賞・国際シネクラブ賞<br />第27回モントリオール国際映画祭AQCC賞</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
